body
{
  font-family: calibri;
  overflow-x: hidden;
}
.App
{
  overflow-x: hidden;
}
.App-header
{
  background: #000;
  padding: 1em;
  //position: fixed;
}
.App-header .icon-top
{
  float: right;
  font-size: 3em;
  color: #fff;
  display: none;
}
.App-logo
{
  max-width: 90%;
}
.nav
{
  margin-left: auto;
  text-align: right;
}
.nav-links
{
  list-style-type: none;
  margin-top: 2em;
  color: #fff;
  //text-align: left;
  margin-left: auto; 
  margin-right: 20px;
}
.nav-links a:link
{
  color: #fff;
}
.nav-links a:visited
{
  color: #fff;
}
.nav-links a:hover
{
  color: #fff;
}
.nav-links li
{
  display: inline-block;
  margin-right: 3em;
  cursor: pointer;
  //padding: 1em;
  border-bottom: 0px solid #fff;
  //transition: border-bottom 1s;;
}
.nav-links li:hover
{
  //background: #333;
  height: 100%;
  border-bottom: 1px solid #fff;
}
.mobile-nav
{
  position: fixed;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100vh;
  background: #000;
  z-index: 2000;
  transition: left .5s;
}
.mobile-nav span
{
  color: #fff;
  font-size: 2em;
  margin-left: 1em;
  font-weight: bold;
  cursor: pointer;
}
.mobile-nav-links
{
  list-style-type: none;
  color: #fff;
  font-weight: bold;
  font-size: 4em;
  text-align: center;
  margin-top: 1em;
  cursor: pointer;
}
.mobile-nav-links a:link
{
  text-decoration: none;
  color: #fff;
}
.mobile-nav-links a:visited
{
  text-decoration: none;
  color: #fff;
}
.hero
{
  height: 85vh;
  widows: 100%;
  background: url('./Untitled-3-Recovered.png');
  background-size: cover;
}
.hero-sec
{
  width: 95%;
  margin: auto;
}
.hero-content
{
  position: absolute;
  top: 150px;
  right: 0px;
  width: 45%;
}
.hero-sec .Mlogo
{
  max-width: 15%;
}
.hero-sec h1
{
  font-family: Edwardian Script ITC;
  font-size: 100px;
  font-weight: bold;
  color: #000;
}
.hero-sec p
{
  font-size: 20px;
  color: #fff;
}
.first-sec
{
  //background: blue;
  width: 95%;
  margin: 4em auto;
  //margin-left: 4.5em;
}
.icon-top
{
  font-size: 4em;
  margin-top: .4em;
  cursor: pointer;
  //color: green !important;
}
.icon-top-2
{
  font-size: 4em;
  margin-top: .4em;
  cursor: pointer;
  //color: green !important;
  position: relative;
  bottom: 30px; 
}
.icon-top-bg
{
  background: #dfdfdf;
  padding: 2em;
  width: 150px;
  border-radius: 100px;
  //margin-left: 4.5em;
  margin: auto;
}
.icon-card
{
  text-align: center;
  padding: 2em;
  width: 100%;
  margin: auto;
}
.icon-card h1
{
  width: 150px;
  margin-top: 1em;
  //width: 150px;
  margin: auto;
}
.icon-card p
{
  width: 150px;
  font-size: 1.1em;
  margin: auto;
}
.second-sec
{
  padding: 4em;
  background: #039ce2;
}
.second-sec p
{
  margin-bottom: 3em;
}
.third-sec
{
  padding: 4em;
}
.fourth-sec
{
  //background: #efefef;
}
.prices
{
  width: 100%;
  //margin-bottom: 4em;
}
.text-card
{
  padding: 2em;
  height: 100%;
  text-align: center;
}
.text-card h1
{
  margin-top: 2.5em;
}
.w-small
{
  width: 10%;
  margin: .2em;
}
.w-small-banner
{
  width: 100%;
}
.App-logo-footer
{
  max-width: 40%;
}
.footer
{
  padding: 4em;
  background: #000;
  color: #fff;
}
.footer-top-sec
{
  border-bottom: 1px solid grey;
  padding-bottom: 4em;
}
.footer .icon-top
{
  font-size: 1.5em;
}
.footer-bottom-sec
{
  margin-top: 4em;
}
.footer-about
{
  width: 50%;
}
.footer-contact
{
  margin-bottom: .5em;
  padding: .2em;
}
.footer-contact p
{
  margin-top: .5em;
}

.overlay
{
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9000;
}
.Contact-modal
{
  width: 80%;
  background: #efefef;
  padding: 2em;
  margin: 4em auto;
}
.Contact-modal form
{
  //background: #fff;
  //padding: 2em;
  margin-top: 2em;
}
.contact-info
{
  display: 'none;';
}

.Contact-modal .contact-info .icon-top
{
  font-size: 1.5em;
}

.map
{
  width: 80%;
  height: 81vh;
  margin-right: 0px;
  margin-left: auto;
  overflow-y: hidden;
}

@media screen and (max-width:800px){
    body
    {
      overflow-x: hidden;
    }
    .nav
    {
      display: none;
    }
    .App-logo
    {
      margin-top: 1.2em;
      max-width: 200%;
    }
    .App-header
    {
      padding: .5em;
    }
    .App-header .icon-top
    {
      display: block;
    }
    .hero
    {
      height: 60vh;
    }
    .hero-content
    {
      position: absolute;
      top: 130px;
      right: 180px;
      width: 45%;
    }
    .hero-sec h1
    {
      font-family: Edwardian Script ITC;
      font-size: 80px;
      margin-top: -.6em;
    }
    .hero-sec .Mlogo
    {
      max-width: 50%;
      margin-left: 15em;
    }
    .mobile-hero-text
    {
      margin-top: -120px;
    }
    .mobile-hero-text h1
    {
      font-family: "Playwrite CU", cursive;
      font-optical-sizing: auto;
      font-weight: bold;
      font-style: normal;
    }
    .hero-sec span
    {
      margin-bottom: .5em;
    }
    .w-small
    {
      width: 30%;
      margin: .2em;
    }
    .App-logo-footer
    {
      max-width: 100%;
      margin-bottom: 2em;
    }
    .footer-about
    {
      width: 100%;
    }
    .hero-sec
    {
      width: 100%;
      margin: auto;
    }
    .hero-sec h1
    {
      font-family: Tahoma;
      font-size: 60px;
      font-weight: bold;
      color: #000;
    }
    .no-mobile
    {
      display: none;
    }
    .first-sec
    {
      //background: blue;
      width: 95%;
      margin: 4em auto;
      //margin-right: -6em;
    }
    .third-sec
    {
      padding: 1em;
      padding-bottom: 4em;
      padding-top: 4em;
    }
    .second-sec
    {
      padding: 1em;
      padding-bottom: 4em;
      padding-top: 4em;
    }
    .footer
    {
      padding: 1em;
      padding-bottom: 4em;
      padding-top: 4em;
    }
    .w-small-banner
    {
      display: none;
    }
    .locations-nav
    {
      display: none;
    }
    .map
    {
      width: 100%;
      margin-right: auto;
      margin-left: auto;
    }
}
.locations-nav
{
  position: fixed;
  padding: 2em;
  width: 30%;
  height: 85vh;
  background: #efefef;
  overflow-y: scroll;
  margin: 0px !important;
  z-index: 5000;
}
.locations-nav h1
{
  padding-bottom: 1em;
  border-bottom: 1px solid #cfcfcf;
}
.locations-location
{
  padding: 2em;
  background: #fff;
  border: 1px solid #dfdfdf;
  border-radius: 5px;
  margin-top: 2em;
}
.bubble
{
  width: 100px;
  background: #fff;
  padding: 1em;
  position: relative;
  bottom: 40px; 
}
.mobile-hero-text
  {
    margin-left: 0em;
  }

@media screen and (min-width: 765px) and (max-width: 800px) {

    .mobile-hero-text
    {
      margin-left: -12em;
    }

}
